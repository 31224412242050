import React from "react";
import {Box, Typography} from "@mui/material";
import ResumeItem from "./ResumeItem";
import ResumeHeader from "./ResumeHeader";

const ResumeParagraph = (props) => {
  return (
    <Typography variant="body1" paragraph="true" sx={{lineHeight: 1.2, marginBottom: '0.3rem'}}>
      {props.children}
    </Typography>
  )
};


const Skill = (props) => {
  return (
    <Box pb={1}>
      <Box pb={0.3}>
        <Typography variant="h3">
          {props.title}
        </Typography>
      </Box>
        <Typography variant="body1">
        <ResumeParagraph>
          {props.children}
        </ResumeParagraph>
      </Typography>
    </Box>
  )
};

const Job = (props) => {

  console.log(props.details);
  const details = props.details.map(d => <ResumeParagraph>{d}</ResumeParagraph>);
  console.log(details);

  return (
    <Box>
      <ResumeItem company={props.company}
                  title={props.title}
                  date={props.date}>
        {props.details.map(d => <ResumeParagraph>&bull; {d}</ResumeParagraph>)}
      </ResumeItem>
    </Box>
  )
};


const ContentResume = () => {
  return (
    <Box>
      <ResumeHeader>Professional Experience</ResumeHeader>

      <Job company="Fortive"
           title="Data Scientist"
           date="September 2020 &ndash; Present"
           details={[
              "Built and deployed a cloud-native document layout analysis tool using PyTorch, Django, and React.",
              "Developed and deployed customer churn models for subscription-based businesses.",
              "Experimented with deep learning approaches to demand forecasting for large product lines.",
              "Led development of an analytics product road map for a business in the IoT wearables space.",
              "Performed entity matching on medical device datasets to enable analytics and text field autocomplete.",
              "Contributed to code reuse by releasing and maintaining internal code packages and applications."
           ]}
      />

      <Job company="Fortive"
           title="Data Science Intern"
           date="June 2020 &ndash; September 2020"
           details={
             [
                "Developed a revenue forecasting model using gradient boosted trees that reduced error by 40%.",
                "Built and deployed a forecasting application including unit tests, documentation, and a CI/CD pipeline.",
                "Organized and led a workshop on UX design, prototyping, and usability testing of data visualizations."
             ]
           }
      />

      <Job company="Northeastern University"
           title="Teaching Assistant - Discrete Math"
           date="January 2020 &ndash; May 2020"
           details={
             [
              "Coached students during office hours; provided feedback on assignments and exams.",
              "Led online review sessions to help students transition to virtual classes during the COVID-19 pandemic."
             ]
           }
      />

      <Job company="Boeing"
           title="Product Manager"
           date="October 2018 &ndash; August 2019"
           details={
             [
                "Managed development of software for execution of computations on large datasets in real time.",
                "Planned and executed user research to identify user pain points and create features to resolve them.",
                "Developed a Python application to extract, analyze, and visualize data from the team’s applications."
             ]
           }
      />


      <Job company="Boeing"
           title="Flight Test Engineer"
           date="June 2014 &ndash; October 2018"
           details={
             [
                "Analyzed flight test data to inform root cause analysis of airplane system anomalies.",
                "Designed and built real-time data analysis and visualization applications for flight test engineers.",
                "Improved customer relations by executing a Flight Test Road Show workshop series.",
                "Mentored team members, developed training courses, and contributed to internal technical publications."
             ]
           }
      />


      <Job company="Rolls-Royce"
           title="Engineering Co-Op"
           date="January 2012 &ndash; August 2013"
           details={
             [
               "Developed thermodynamic models in C++ for optimization of new and novel gas turbine engine cycles.",
               "Performed a Monte Carlo analysis to determine effects of seal growth on engine bleed cooling"
             ]
           }
      />

      <ResumeHeader>Education</ResumeHeader>

      <ResumeItem company="Northeastern University"
                  title="Master of Science in Computer Science"
                  date="August 2021"
                  gpa="4.0/4.0"
      >
        <ResumeParagraph>
          Distributed systems, cybersecurity, machine learning, operating systems
        </ResumeParagraph>

        <ResumeParagraph>
          <b>Thesis</b>: Evaluation of Synthetic Training Data and Training-Data-Augmentation Techniques for Object Detection in Ground-Penetrating Radar Data Using Deep-Learning Models
        </ResumeParagraph>
      </ResumeItem>

      <ResumeItem company="University of Washington"
                  title="Certificate in Python Programming"
                  date="June 2019">
        <ResumeParagraph>
          Python, web development, algorithms, data structures, statistics
        </ResumeParagraph>
      </ResumeItem>

      <ResumeItem company="Purdue University"
                  title="B.S. Aeronautical & Astronautical Engineering"
                  date="June 2019"
                  gpa="3.8/4.0"
      >
        <ResumeParagraph>
          Jet propulsion, aerodynamics, computational fluid dynamics, control theory, flight test
        </ResumeParagraph>
      </ResumeItem>


      <ResumeHeader>Skills</ResumeHeader>

      <Skill title="Programming Languages">
          Python, C, C++, Java, JavaScript, shell scripting, assembly
      </Skill>

      <Skill title="Data Science">
          pandas, scikit-learn, TensorFlow, Keras, PyTorch, deep learning, machine learning, entity matching, data visualization
      </Skill>

      <Skill title="Software Development">
          Linux, Git, CI/CD, DevOps, Docker, SQL & NoSQL databases, AWS, Terraform, cloud-native design, distributed systems, object-oriented programming
      </Skill>

      <Skill title="Web Development">
          Django, React, Material UI, HTML+CSS, GraphQL, API development, microservices
      </Skill>

      <Skill title="Electronics & Devices">
          Prototyping, hardware design, wearables, CircuitPython, micropython, IoT/smart home, Autodesk EAGLE, Fusion360
      </Skill>

      <Skill title="Product & Design">
          Jira, Sketch, InVision, lean product development, user research, usability testing, technical writing, LaTeX
      </Skill>

      <ResumeHeader>Projects</ResumeHeader>

      <ResumeItem title="MS Computer Science Thesis">
        <ResumeParagraph>
          <i>Evaluation of Synthetic Training Data and Training-Data-Augmentation Techniques for Object Detection in Ground-Penetrating Radar Data Using Deep-Learning Models</i>
        </ResumeParagraph>
        <ResumeParagraph>
          This work examines the current state of deep learning in the subsurface imaging field and expands upon it by evaluating the real-world performance of a synthetically-trained neural network model for object detection in GPR images. Further, three data augmentation techniques for GPR data are proposed and evaluated on a real-world test set: random cropping, negative augmentation, and real noise application.
        </ResumeParagraph>
      </ResumeItem>

      <ResumeItem title="Grain & Gather">
        <ResumeParagraph>
          <i>A website to showcase my creative side</i>
        </ResumeParagraph>
        <ResumeParagraph>
          I built myself another website using Django and React to showcase my various creative pursuits including interior design, watercolor painting, woodworking, and custom framing. I use it as a platform to showcase works by other local artists as well. <a href="https://grainandgather.com">Check it out here</a>.
        </ResumeParagraph>
      </ResumeItem>

      <ResumeItem title="WiFi Smart Switch PenTest">
        <ResumeParagraph>
          <i>Cybersecurity class project to complete a security assessment of an IoT device</i>
        </ResumeParagraph>
        <ResumeParagraph>
          For this class project, I completed a security assessment detailing potential attackers and their incentives as well as types of attacks and their associated risks. I started by preforming open source intelligence gathering and network traffic snooping to identify attack vectors. Next, I conducted four types of attack: supply chain attack, direct memory access, DoS, and replay attack.
        </ResumeParagraph>
      </ResumeItem>

      <ResumeItem title="Lighthouse">
        <ResumeParagraph>
          <i>An Open Source C Unit Test Framework</i>
        </ResumeParagraph>
        <ResumeParagraph>
          I'm the creator of Lighthouse, a (very) lightweight unit testing framework for C. The project started when I decided to build some testing infrastructure to make my life easier while doing my algorithms homework. I realized it could be useful to others, so I created a project based on it and shared it with my classmates. After improving on the initial version, I released it as an open source project.
        </ResumeParagraph>
      </ResumeItem>

      <ResumeItem title="Ocularize">
        <ResumeParagraph>
          <i>Online Intake Forms to Pandemic-Proof Family-Owned Medical Practice</i>
        </ResumeParagraph>
        <ResumeParagraph>
          At the start of the COVID-19 pandemic, I was looking for a way to help out in some way. My partner's father co-owns a small optometry practice in San Jose, CA and was looking for a way to move the intake process (currently consisting of paper forms) online in order to meet guidelines and enhance the safety of patients and staff. I offered to help out and the result was <a href="https://www.ocularize.com/">Ocularize</a>, a secure portal through which patients can submit their intake forms. I built an accompanying staff website which allows staff members to log on and view the forms. The tool has since been used to process thousands of intake forms. During this challenging time, wins for small businesses are something we should all celebrate!
        </ResumeParagraph>
      </ResumeItem>


      <ResumeHeader>Volunteer Experience</ResumeHeader>

      <ResumeItem company="Boeing Employees alpine society (BOEALPS)"
                  title="Climbing & Mountaineering Instructor"
                  date="2015 &ndash; Present">
        <ResumeParagraph>
          Outside of work, I volunteer as a climbing instructor. As Head Instructor, I worked closely with students and instructors to improve their climbing and safety skills. I executed a promotional campaign that increased the number of applicants by 50% over the previous two years. I also automated processing of student application data using Python and Jupyter Notebook to generate interactive summary reports for admissions committee members.
        </ResumeParagraph>
      </ResumeItem>

    </Box>
  )
};

export default ContentResume