class Project {
  constructor(title, description, image, tech_stack, github_link=null, site_link=null, invision_link=null) {
    this.title = title;
    this.description = description;
    this.image = image;
    this.tech_stack = tech_stack;
    this.github_link = github_link;
    this.site_link = site_link;
    this.invision_link = invision_link;
  }
}

export default Project;