import { createTheme, responsiveFontSizes} from "@mui/material/styles"
import deepPurple from '@mui/material/colors/deepPurple';
import grey from '@mui/material/colors/grey';

let jeanTheme = createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontWeight: 500,
      letterSpacing: -0.2,
      fontSize: '1.7rem'
    },
    h2: {
      fontWeight: 100,
      letterSpacing: -0.5,
      fontSize: '2rem',
      color: deepPurple[900]
    },
    h3: {
      fontFamily: '"Libre Franklin", sans-serif',
      fontWeight: 500,
      letterSpacing: 0,
      fontSize: '1.1rem'
    },
    h4: {
      fontWeight: 500,
      letterSpacing: -0.1,
      lineHeight: 1.1,
      fontSize: '1.7rem',
        '@media (min-width:600px) and (max-width:1100px)': {
          fontSize: '0.5rem',
        },
    },
    h5: {
      fontWeight: 500,
      letterSpacing: -0.1,
      lineHeight: 1.1,
      fontSize: '1.2rem'
    },
    h6: {
      fontWeight: 100,
      letterSpacing: 0.2,
      lineHeight: 1.2,
      fontSize: '0.9rem'
    },
    body1: {
      fontWeight: 300,
      letterSpacing: 0.2,
      lineHeight: 1.6,
      fontSize: '0.9rem',
      color: grey[800]
    },
    body2: {
      fontWeight: 300,
      letterSpacing: 0.25,
      fontSize: '0.8rem'
    },
    subtitle1: {
      fontWeight: 600,
      letterSpacing: 0.15
    },
    subtitle2: {
      fontFamily: '"Merriweather", serif',
      fontWeight: '700i',
      letterSpacing: 0.1
    },
    button: {
      fontFamily: '"Libre Franklin", sans-serif',
      fontWeight: 700,
      letterSpacing: 1.5
    },
    caption: {
      fontFamily: '"Roboto Mono", sans-serif',
      fontWeight: 300,
      letterSpacing: 0.4
    },
    overline: {
      fontWeight: 400,
      letterSpacing: 1.9,
      lineHeight: 1.2,
      fontSize: '0.75rem'
    },

  },
  palette: {
    primary: { main: '#FAFAFA' },
    secondary: { main: '#482880' }
  },
  shape: {
    borderRadius: 0
  },
  spacing: (value => value * 8),
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 700,
      lg: 1100,
      xl: 1920,

    }
  }
});

jeanTheme = responsiveFontSizes(jeanTheme);
export default jeanTheme;