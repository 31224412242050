import React from "react";
import {Box, CardMedia, Typography} from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";



const ProjectCard = (props) => {
  return (
    <Box width="100%" pb={8}>
      <Hidden mdDown>
        <Stack direction="row">
          <Box pr={2} sx={{width: 300}}>
            <CardMedia
              component="img"
              image={require("../images/" + props.project.image)}
              alt={props.project.title}
              sx={{width: "15vw"}}
            />
          </Box>

          <Box>
            <Typography variant="h3" pb={.5}>{props.project.title}</Typography>

            <Typography variant="body2">{props.project.description}</Typography>

            <Stack direction="row" spacing={1.8} justifyContent="flex-end" pt={1}>
              {props.project.tech_stack.map(t => <Typography variant="caption">{t}</Typography>)}
            </Stack>

            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {props.project.github_link ? (
                  <IconButton href={props.project.github_link}>
                    <GitHubIcon />
                  </IconButton>
                ) : null}

              {props.project.site_link ? (
                  <IconButton href={props.project.site_link}>
                    <LaunchIcon />
                  </IconButton>
                ) : null}

              {props.project.invision_link ? (
                  <IconButton href={props.project.invision_link}>
                    <DesignServicesIcon />
                  </IconButton>
                ) : null}
            </Stack>

          </Box>
        </Stack>
      </Hidden>

      <Hidden mdUp>
        <Stack direction="column">
          <Box pb={2} sx={{width: 300}}>
            <CardMedia
              component="img"
              image={require("../images/" + props.project.image)}
              alt={props.project.title}
              sx={{width: "95vw"}}
            />
          </Box>

          <Box>
            <Typography variant="h3" pb={.5}>{props.project.title}</Typography>

            <Typography variant="body2">{props.project.description}</Typography>

            <Stack direction="row" spacing={1.8} justifyContent="flex-start" pt={1}>
              {props.project.tech_stack.map(t => <Typography variant="caption">{t}</Typography>)}
            </Stack>

            <Stack direction="row" spacing={1} justifyContent="flex-start">
              {props.project.github_link ? (
                  <IconButton href={props.project.github_link}>
                    <GitHubIcon />
                  </IconButton>
                ) : null}

              {props.project.site_link ? (
                  <IconButton href={props.project.site_link}>
                    <LaunchIcon />
                  </IconButton>
                ) : null}

              {props.project.invision_link ? (
                  <IconButton href={props.project.invision_link}>
                    <DesignServicesIcon />
                  </IconButton>
                ) : null}
            </Stack>

          </Box>
        </Stack>
      </Hidden>





    </Box>
  )
};

export default ProjectCard