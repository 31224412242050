import React from "react";
import {Box, Hidden, Typography } from "@mui/material";
import AboutCard from "./AboutCard";

const ContentAbout = () => {
  // const theme = useTheme();
  // const large = useMediaQuery(theme.breakpoints.up('sm'));
  // const aboutCard = large ? "" : <AboutCard class="small"/>;

  return (
    <Box pb={2}>

      <Hidden smUp>
        <AboutCard class="small"/>
      </Hidden>

      <Box pt={5}>
        <Box pb={2}>
          <Typography variant="h2">
            Career Update!
          </Typography>
        </Box>

        <Typography variant="body1" paragraph>
          I'm thrilled to announce that I have accepted a Senior Applied Data Scientist role at Amperity! I'll be joining the team at the end of June and I am so excited to be starting this new chapter in my career journey.
        </Typography>

        <Box pb={2} pt={3}>
          <Typography variant="h2">
            About Jean
          </Typography>
        </Box>

        <Typography variant="body1" paragraph>
          Jean is a full stack data scientist with prior experience in product and engineering. She has experience across the full data science and software development stack including data analysis, machine learning, full stack web development, cloud-native architecture, and DevOps. She enjoys working on data science projects and then deploying the resulting models into production grade applications.
        </Typography>

        <Typography variant="body1" paragraph>
          Jean holds a masters degree in computer science from Northeastern and a bachelors in aeronautical & astronautical engineering from Purdue. She has also learned a lot about product development, UX design, and user research from working as a product manager and using these skills on personal projects.
        </Typography>

        <Typography variant="body1" paragraph>
          Outside of work, Jean volunteers as a climbing and mountaineering instructor. She has taught climbers of all levels and recently served as Head Instructor for the Basic Rock Climbing Class.
        </Typography>

        <Typography variant="body1" paragraph>
          When she's not coding, Jean can be found backcountry skiing, cycling, climbing, cooking, or frantically searching the web for the release date of J. Kenji Lopez-Alt’s next book.
        </Typography>
      </Box>
    </Box>
  )
};

export default ContentAbout