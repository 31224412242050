import React from "react";
import { Typography, Box } from "@mui/material";
import ContactInfo from "./ContactInfo";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import GitHubIcon from '@mui/icons-material/GitHub';


const ContentContact = () => {
  return (
    <Box>
      <Box pt={3} pb={2}>
        <Typography variant="h2">
          Contact Information
        </Typography>
      </Box>

      <Box px={1}>
        <ContactInfo icon={<PlaceIcon fontSize="small" />} address="Seattle, WA"/>

        <ContactInfo icon={<EmailIcon fontSize="small"/>}
                     address="jeanruggiero@gmail.com"
                     href="mailto:jeanruggiero@gmail.com"/>

        <ContactInfo icon={<LinkedInIcon fontSize="small" />}
                     address="linkedin.com/in/jean-ruggiero"
                     href="https://www.linkedin.com/in/jean-ruggiero/"/>

        <ContactInfo icon={<GitHubIcon fontSize="small" />}
                     address="github.com/jeanruggiero"
                     href="https://github.com/jeanruggiero"/>
      </Box>

    </Box>
  )
};

export default ContentContact