import {Box} from "@mui/material";
import AboutCard from "./AboutCard";
import React from "react";


const Sidebar = () => {
  return (
    <Box pr="2vw">
      <AboutCard/>

      {/*<Hidden mdDown>*/}
      {/*  <HighlightsBar/>*/}
      {/*</Hidden>*/}
    </Box>
  )
};

export default Sidebar
