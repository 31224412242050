import React from 'react';
import {Box, Grid, Hidden} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles"
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import jeanTheme from "./styles";
import NavBar from "./components/NavBar";
import ContentAbout from "./components/ContentAbout"
import Footer from "./components/Footer";
import ContentResume from "./components/ContentResume";
import ContentContact from "./components/ContentContact";
import Sidebar from "./components/Sidebar"
import ContentProjects from "./components/ContentProjects";


export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {panel: 'about'};
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(panel) {
    this.setState({panel: panel});
  };

  render() {
    return (
      <ThemeProvider theme={jeanTheme}>
        <BrowserRouter>
          <Box pt={3} px="2vw" style={{overflowX: 'hidden'}}>
            <Hidden mdDown>
              <Box p={1} />
            </Hidden>

            <Grid container spacing={2}>
              <Hidden smDown>
                {/*<Hidden smDown>*/}
                {/*  <Grid item md={1}/>*/}
                {/*</Hidden>*/}
                <Grid item sm={4} md={4} lg={3}>
                  <Sidebar/>
                </Grid>
              </Hidden>

              <Grid item xs={12} sm={8} md={8} lg={7} xl={7}>
                <NavBar panel={this.state.panel} onClick={this.handleClick}/>

                <Routes>
                  <Route path="/" element={<ContentAbout/>} exact/>
                  <Route path="/about" element={<ContentAbout/>}/>
                  <Route path="/resume" element={<ContentResume/>}/>
                  <Route path="/contact" element={<ContentContact />} />
                  <Route path="/projects" element={<ContentProjects />} />
                  <Route component={ContentAbout}/>
                </Routes>
              </Grid>
            </Grid>

            {/*<Hidden lgUp>*/}
            {/*  <HighlightsBar/>*/}
            {/*</Hidden>*/}
          </Box>

          <Footer/>
        </BrowserRouter>
      </ThemeProvider>
    )
  }
};
