import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";

const AboutCard = (props) => {
  return (
    <Card elevation={1} className={props.class}>
      <CardMedia
        component="img"
        image={require("../images/head_shot_square.png")}
        title="Jean Ruggiero" />

      <CardContent>
        <Box pb={1.5}>
          <Box pb={0.5}>
            <Typography variant="h4">
              Jean Ruggiero
            </Typography>
          </Box>

          <Typography variant="h6">
            Full Stack Data Scientist
          </Typography>
        </Box>

        <Typography variant="body2">
           Jean Ruggiero will be joining Amperity as a Senior Applied Data Scientist next month. She loves tackling deep learning problems and deploying production machine learning applications. Jean brings a deep sense of empathy to her work each and every day to build AI for humans.
        </Typography>
      </CardContent>
    </Card>
  )
};

export default AboutCard