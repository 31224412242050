import React from "react";
import {Box, Typography} from "@mui/material";
import ProjectCard from "./ProjectCard";
import projects from "./projects/projects";


const ContentProjects = (props) => {

  return (
    <Box>
      <Box pt={3} pb={4}>
        <Typography variant="h2">
          Projects
        </Typography>
      </Box>

      {projects.map(project => <ProjectCard project={project}/>)}

    </Box>
  )

};

export default ContentProjects;