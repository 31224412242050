import React from "react";
import { Box, Grid } from "@mui/material";
import NavButton from "./NavButton";

const NavBar = (props) => {

  return (

    <Box pb={1}>
      <Grid container>

        <Grid item xs>
          <Box display="flex" justifyContent="center">
            <NavButton text='About'
                       icon="person"
                       link="/about"/>
          </Box>
        </Grid>


        <Grid item xs>
          <Box display="flex" justifyContent="center">
            <NavButton text='Resume'
                       icon="format_align_left"
                       link="/resume"/>
          </Box>
        </Grid>
        
        <Grid item xs>
          <Box display="flex" justifyContent="center">
            <NavButton text='Projects'
                       icon="code"
                       link="/projects"/>
          </Box>
        </Grid>

        <Grid item xs>
          <Box display="flex" justifyContent="center">
            <NavButton text='Contact'
                       icon="email"
                       link="/contact"/>
          </Box>
        </Grid>
      </Grid>

    </Box>
  )
};

export default NavBar;